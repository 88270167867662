import React from 'react'
import twitterLogo from '../images/twitter-logo.png'

export const SupportCta: React.FC = () => {
  return (
    <section className="customer-suppert-cta-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="customer-suppert-cta-wrapper">
              <div className="row">
                <div className="col-md-7">
                  <div className="customer-suppert-cta-title-wrap">
                    <h4 className="customer-suppert-cta-title">
                      Stay connected for updates & customer support.
                    </h4>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="customer-suppert-cta-links-wrap">
                    <ul className="customer-suppert-cta-links">
                      <li>
                        <a
                          href="https://twitter.com/PorterProxies"
                          target="_blank"
                          rel="noreferrer noopoener">
                          <img src={twitterLogo} alt="Twitter" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
