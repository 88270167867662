import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useForm } from 'react-hook-form'
import 'twin.macro'
import { Button, Input, InputError } from '../../primitives'
import { AuthAction } from './components/AuthAction'

const links = [{ label: 'I remember now', href: '/login' }]

export const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  const onSubmit = (payload: unknown) => alert(JSON.stringify(payload))

  return (
    <AuthAction title="Reset password" links={links}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div tw="flex flex-col gap-3">
          <Input
            type="email"
            placeholder="E-mail"
            variant="light"
            tw="w-full"
            {...register('email', { required: true })}
          />
          <InputError error={errors.email} />
          <Button type="submit" variant="primary">
            Reset
          </Button>
        </div>
      </form>
    </AuthAction>
  )
}
