import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export const variants = {
  default: tw`bg-transparent`,
  light: tw`bg-accent-300 border-gray border-opacity-30`
}

interface Props {
  variant?: keyof typeof variants
}

export const Input = styled.input<Props>`
  ${tw`py-2 px-3 rounded-md border text-sm max-w-full`}
  ${(props) => variants[props.variant || 'default']}
`

export const InputLabel = styled.label`
  ${tw`font-semibold text-gray py-1 text-sm`}
`

export const InputError: React.FC<{ error?: any }> = ({ error }) => {
  if (!error) return null

  const getMessage = () => {
    if (error.message) return error.message
    if (error.type === 'required') return 'This field is required'
    return 'Invalid input'
  }

  return <div tw="text-red text-sm">{getMessage()}</div>
}
