import React, { useState } from 'react'
import { Plan } from './Plan'

interface Props {
  planId: string
  title: string
  description: string
  features: string[]
  quantities: number[]
  prices: number[]
  isSoldOut?: boolean
}

export const Residental: React.FC<Props> = ({
  planId,
  title,
  description,
  features,
  quantities,
  prices,
  isSoldOut
}) => {
  const [quantity, setQuantity] = useState(0)

  const handlePurchase = () => {
    alert(
      JSON.stringify({
        planId,
        quantity: quantities[quantity],
        price: prices[quantity]
      })
    )
  }

  return (
    <div className="col-md-4">
      <Plan title={title}>
        <Plan.Group>
          <Plan.Text>{description}</Plan.Text>
        </Plan.Group>

        <Plan.Group>
          <div>
            {features.map((feature) => (
              <p className="plan-feature" key={feature}>
                {feature}
              </p>
            ))}
          </div>
        </Plan.Group>

        <Plan.Slider
          label="Quantity"
          values={quantities}
          unit="GB"
          value={quantity}
          setValue={setQuantity}
          isDisabled={isSoldOut}
        />

        <Plan.Buy
          price={prices[quantity]}
          isSoldOut={isSoldOut}
          handlePurchase={handlePurchase}
        />
      </Plan>
    </div>
  )
}
