import React, { useState } from 'react'
import 'twin.macro'
import { Button, Progress } from '../../../primitives'
import porterIcon from '../images/porter-icon.png'

interface Props {
  id: string
}

export const OrderDetails: React.FC<Props> = ({ id }) => {
  const [showMore, setShowMore] = useState(false)

  const mobileDetails = [
    ['Order #', id],
    ['Qty', '2.0GB'],
    ['Total', '$24.00'],
    ['Ordered', '2021-10-09'],
    ['Active Time', '23 Days Left']
  ]

  return (
    <div tw="border-t border-accent-700">
      <div tw="flex flex-row lg:(grid grid-cols-10) px-4 py-3 items-center">
        <div tw="hidden col-span-1 lg:(flex flex-row gap-2)">
          <img src={porterIcon} alt="" tw="h-6 w-6 hidden lg:inline" />
          {id}
        </div>
        <div tw="flex-1 lg:col-span-3">Advanced Residential Package</div>
        <div tw="hidden lg:(block col-span-1)">2.0 GB</div>
        <div tw="hidden lg:(block col-span-1)">$24.00</div>
        <div tw="hidden lg:(block col-span-1)">2021-10-09</div>
        <div tw="hidden lg:(block col-span-1)">23 Days Left</div>
        <div tw="lg:col-span-2 flex gap-2 justify-end">
          <Button variant="green">Active</Button>
          <Button onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Less' : 'More'}
          </Button>
        </div>
      </div>

      {showMore && (
        <div tw="px-4 pb-3">
          <table tw="flex flex-col w-full lg:hidden">
            <tbody>
              {mobileDetails.map(([key, val]) => (
                <tr key={key}>
                  <td tw="text-gray uppercase font-semibold text-sm">{key}</td>
                  <td tw="pl-5">{val}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div tw="text-gray text-sm font-semibold">
            1.3 of 2.0 GB remaining
          </div>
          <Progress value={13} max={20} tw="w-72" />
        </div>
      )}
    </div>
  )
}
