import React from 'react'

interface TabProps {
  tabId: number
  isActive?: boolean
}

type IOrderSection = React.FC & {
  Tabs: React.FC
  Tab: React.FC<TabProps>
  Content: React.FC
  TabContent: React.FC<TabProps>
}

export const OrderSection: IOrderSection = ({ children }) => {
  return (
    <section className="our-pricing-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-title-wrapper">
              <h5 id="buy" className="main-pre-title-text">
                Pricing
              </h5>
              <h3 className="main-title-text">Choose Your Plan</h3>
            </div>
          </div>
        </div>
        {children}
      </div>
    </section>
  )
}

OrderSection.Tabs = ({ children }) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="chosse-your-plan-wrapper">
          <div className="chosse-your-plan-wrap">
            <div className="chosse-your-plan-button">
              <ul className="nav nav-pills" id="pills-tab" role="tablist">
                {children}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

OrderSection.Tab = ({ children, tabId, isActive }) => {
  return (
    <li className="nav-item" role="presentation">
      <a
        className={`nav-link ${isActive ? 'active' : ''}`}
        id={`pills-plan${tabId}-tab`}
        data-toggle="pill"
        href={`#plan${tabId}`}
        role="tab"
        aria-controls={`pills-plan${tabId}`}
        aria-selected={isActive ? 'true' : 'false'}>
        {children}
      </a>
    </li>
  )
}

OrderSection.Content = ({ children }) => {
  return (
    <div className="tab-content" id="pills-tabContent">
      {children}
    </div>
  )
}

OrderSection.TabContent = ({ children, tabId, isActive }) => {
  return (
    <div
      className={`tab-pane fade ${isActive ? 'show active' : ''}`}
      id={`plan${tabId}`}
      role="tabpanel"
      aria-labelledby={`pills-plan${tabId}-tab`}>
      <div className="row d-flex justify-content-center">{children}</div>
    </div>
  )
}
