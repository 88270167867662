import { Router } from '@reach/router'
import React from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { GlobalStyles as TwinStyles, theme } from 'twin.macro'
import { ForgotPassword } from '../auth/ForgotPassword'
import { Login } from '../auth/Login'
import { Register } from '../auth/Register'
import { Dashboard } from '../dashboard/Dashboard'
import { Residental } from '../dashboard/Residental'
import { Homepage } from '../homepage/Homepage'
import { ErrorBoundary } from './ErrorBoundary'
import { GlobalStyles } from './GlobalStyles'
import { IntlProvider } from './IntlProvider'

const App: React.FC = () => {
  return (
    <IntlProvider>
      <ErrorBoundary>
        <SkeletonTheme
          color={theme`colors.accent.300`}
          highlightColor={theme`colors.accent.600`}>
          <TwinStyles />
          <GlobalStyles />
          <Router>
            <Homepage path="/" />
            <Login path="/login" />
            <Register path="/register" />
            <ForgotPassword path="/forgot-password" />
            <Dashboard path="/dashboard" />
            <Residental path="/dashboard/residental" />
          </Router>
        </SkeletonTheme>
      </ErrorBoundary>
    </IntlProvider>
  )
}

export default App
