import { theme } from 'twin.macro'

interface Props {
  progress: number
  radius?: number
  stroke?: number
  color?: string
}

export const ProgressRing: React.FC<Props> = ({
  radius = 35,
  stroke = 8,
  progress,
  color
}) => {
  const normalizedRadius = radius - stroke
  const circumference = normalizedRadius * 2 * Math.PI
  const strokeDashoffset = circumference - progress * circumference

  return (
    <svg height={radius * 2} width={radius * 2}>
      <circle
        tw="absolute"
        fill="transparent"
        cx={radius}
        cy={radius}
        r={normalizedRadius}
        strokeWidth={stroke}
        strokeLinecap="round"
        stroke={theme`colors.accent.300`}></circle>
      <circle
        tw="absolute"
        fill="transparent"
        cx={radius}
        cy={radius}
        r={normalizedRadius}
        strokeWidth={stroke}
        strokeDasharray={circumference + ' ' + circumference}
        strokeLinecap="round"
        stroke={color}
        strokeDashoffset={strokeDashoffset}
        transform={`rotate(-90 ${radius} ${radius})`}></circle>
    </svg>
  )
}
