import React from 'react'

interface Props {
  question: string
  answer: string
  id: string
}

type IFAQ = React.FC<Props> & { Section: React.FC }

export const FAQ: IFAQ = ({ question, answer, id }) => {
  const isDefault = id === 'One'

  return (
    <div className="card">
      <div className="card-header" id={`heading${id}`}>
        <h2 className="mb-0">
          <button
            className={`btn btn-link btn-block text-left ${
              !isDefault ? 'collapsed' : ''
            }`}
            type="button"
            data-toggle="collapse"
            data-target={`#collapse${id}`}
            aria-expanded={isDefault ? 'true' : 'false'}
            aria-controls={`collapse${id}`}>
            {question}
          </button>
        </h2>
      </div>
      <div
        id={`collapse${id}`}
        className={`collapse ${isDefault ? 'show' : ''}`}
        aria-labelledby={`heading${id}`}
        data-parent="#faqsAccordion">
        <div className="card-body">{answer}</div>
      </div>
    </div>
  )
}

FAQ.Section = ({ children }) => {
  return (
    <section className="faq-section">
      <div className="faq-section-line-bg"></div>
      <div className="faq-section-line-bg2"></div>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-title-wrapper">
              <h5 className="main-pre-title-text">FAQ</h5>
              <h3 className="main-title-text">Have any questions?</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="accordion custom-faqs-accordion" id="faqsAccordion">
            <div className="col-md-12">{children}</div>
          </div>
        </div>
      </div>
    </section>
  )
}
