import React from 'react'
import ourFeaturesIcon from '../images/our-features-icon.png'

interface Props {
  heading: string
  content: string
}

type IFeature = React.FC<Props> & { Section: React.FC }

export const Feature: IFeature = ({ heading, content }) => {
  return (
    <div className="col-md-4">
      <div className="our-features-box-wrapper">
        <div className="our-features-box-wrap">
          <div className="our-features-box-head">
            <div className="our-features-box-icon">
              <img
                src={ourFeaturesIcon}
                alt=""
                className="our-features-box-icon-image"
              />
            </div>
            <div className="our-features-box-title">
              <h3 className="our-features-box-title-text">{heading}</h3>
            </div>
          </div>
          <div className="our-features-box-body">
            <p>{content}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Feature.Section = ({ children }) => {
  return (
    <section className="our-features-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="main-title-wrapper">
              <h5 className="main-pre-title-text">Features</h5>
              <h3 className="main-title-text">What sets us apart?</h3>
            </div>
          </div>
        </div>
        <div className="row">{children}</div>
      </div>
    </section>
  )
}
