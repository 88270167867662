import styled from 'styled-components'
import tw, { theme } from 'twin.macro'

export const Progress = styled.progress`
  ${tw`h-2`}

  ::-webkit-progress-bar {
    ${tw`bg-gray bg-opacity-20 rounded`}
  }

  ::-webkit-progress-value {
    ${tw`rounded`}
    background: ${(props) => props.color || theme`colors.gray`};
  }
`
