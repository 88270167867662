import React, { useState } from 'react'
import { Plan } from './Plan'

interface Props {
  planId: string
  title: string
  description: string
  countries: string[]
  quantities: number[]
  periods: number[]
  getPrice: (quantity: number, period: number) => number
  isSoldOut?: boolean
}

export const Complex: React.FC<Props> = ({
  planId,
  title,
  description,
  countries,
  quantities,
  periods,
  getPrice,
  isSoldOut
}) => {
  const [quantity, setQuantity] = useState(0)
  const [period, setPeriod] = useState(0)
  const [country, setCountry] = useState<string | null>(null)

  const handlePurchase = () => {
    alert(
      JSON.stringify({
        planId,
        country,
        quantity: quantities[quantity],
        period: periods[period],
        price: getPrice(quantities[quantity], periods[period])
      })
    )
  }

  return (
    <div className="col-md-6">
      <Plan title={title}>
        <Plan.Group>
          <Plan.Text>{description}</Plan.Text>
        </Plan.Group>

        <Plan.Select
          label="Country"
          options={countries}
          value={country}
          setValue={setCountry}
        />

        <Plan.Slider
          label="Quantity"
          unit="IPs"
          values={quantities}
          value={quantity}
          setValue={setQuantity}
          isDisabled={isSoldOut}
        />

        <Plan.Slider
          label="Period"
          unit="Days"
          values={periods}
          value={period}
          setValue={setPeriod}
          isDisabled={isSoldOut}
        />

        <Plan.Buy
          price={getPrice(quantities[quantity], periods[period])}
          isSoldOut={isSoldOut}
          handlePurchase={handlePurchase}
        />
      </Plan>
    </div>
  )
}
