import React from 'react'
import tw from 'twin.macro'
import { Listbox } from '@headlessui/react'
import { ReactComponent as DropdownIcon } from '../images/dropdown.svg'

type Option = unknown & {
  id: string | number
  name: string
  isDisabled?: boolean
}

const buttonVariants = {
  default: tw`border-accent-600 hover:(border-gray border-opacity-40)`,
  light: tw`bg-accent-300 border-gray border-opacity-30 hover:border-opacity-70`
}

type Variant = keyof typeof buttonVariants

interface Props {
  label?: string
  variant?: Variant
  options: Option[]
  selected: Option
  onChange: (s: Option) => void
}

export const Select: React.FC<Props> = ({
  variant = 'default',
  label,
  options,
  selected,
  onChange
}) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      <div tw="relative">
        {label && (
          <Listbox.Label tw="font-semibold text-gray py-1 text-sm">
            {label}
          </Listbox.Label>
        )}

        <Listbox.Button
          css={[
            tw`relative py-2 pl-3 pr-10 rounded-md text-left w-full focus:outline-none border text-sm transition duration-150`,
            buttonVariants[variant]
          ]}>
          <span tw="block truncate">{selected.name}</span>

          <span tw="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <DropdownIcon />
          </span>
        </Listbox.Button>

        <Listbox.Options tw="absolute w-full overflow-auto bg-accent-0 rounded-md shadow-lg max-h-60 focus:outline-none z-10 border border-accent-300">
          {options.map((option) => (
            <Listbox.Option
              key={option.id}
              value={option}
              disabled={option.isDisabled}
              css={[
                tw`py-2 px-3 text-sm`,
                selected.id === option.id ? tw`text-gray` : tw`cursor-pointer`
              ]}>
              {option.name}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  )
}
