import React from 'react'

interface Props {
  title: string
}

interface BuyProps {
  price: number
  isSoldOut?: boolean
  handlePurchase: () => void
}

interface SliderProps {
  label: string
  values: (string | number)[]
  unit: string
  value: number
  setValue: (v: number) => void
  isDisabled?: boolean
}

interface SelectProps {
  label: string
  options: string[]
  value: string | null
  setValue: (v: string | null) => void
}

type IPlan = React.FC<Props> & {
  Text: React.FC
  Group: React.FC
  Buy: React.FC<BuyProps>
  Slider: React.FC<SliderProps>
  Select: React.FC<SelectProps>
}

export const Plan: IPlan = ({ title, children }) => {
  return (
    <div className="individual-plan-infobox-wrapper">
      <div className="individual-plan-infobox-wrap">
        <div className="individual-plan-head">
          <div className="individual-plan-title">
            <h4 className="individual-plan-title-text">{title}</h4>
          </div>
        </div>
        <div className="individual-plan-body">
          <div className="individual-plan-form">{children}</div>
        </div>
      </div>
    </div>
  )
}

Plan.Text = ({ children }) => {
  return <div className="individual-plan-info-text">{children}</div>
}

Plan.Group = ({ children }) => {
  return <div className="form-group">{children}</div>
}

Plan.Buy = ({ price, isSoldOut, handlePurchase }) => {
  return (
    <div className="form-row">
      <div className="col-sm-3 col-lg-4 col-md-3 col-xs-12">
        <div className="individual-plan-price">${price.toFixed(2)}</div>
      </div>
      <div className="col-sm-9 col-lg-8 col-md-9 col-xs-12 text-right">
        <button
          id="advanced_buy"
          type="button"
          className="btn btn-primary purchase-plan-btn"
          disabled={isSoldOut}
          onClick={() => !isSoldOut && handlePurchase()}>
          {isSoldOut ? 'Sold Out' : 'Purchase'}
        </button>
      </div>
    </div>
  )
}

Plan.Slider = ({ label, values, unit, value, setValue, isDisabled }) => {
  return (
    <Plan.Group>
      <div className="range-slider">
        <label>{label}</label>
        <span id="isp_quantity_output" className="range-slider__value">
          {values[value]}
        </span>
        &nbsp;{unit}
        <input
          id="isp_quantity_slider"
          className="form-control-range range-slider__range"
          type="range"
          value={value}
          min={0}
          max={values.length - 1}
          onChange={(e) => setValue(~~e.target.value)}
          disabled={isDisabled}
        />
      </div>
    </Plan.Group>
  )
}

Plan.Select = ({ label, options, value, setValue }) => {
  return (
    <Plan.Group>
      <label htmlFor="isp_location">{label}</label>
      <select
        className="form-control"
        id="isp_location"
        value={value || 'Pick'}
        onChange={(e) => setValue(e.target.value)}>
        <option disabled value="Pick">
          Pick
        </option>

        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </Plan.Group>
  )
}
