import React from 'react'
import whiteLogo from '../images/porter-white-logo.png'
import navbarToggler from '../../../images/navbar-toggler-icon.png'

export const Navbar: React.FC = () => {
  return (
    <header className="site-header">
      <nav className="container navbar navbar-expand-lg">
        <a className="navbar-brand" href="/">
          <img src={whiteLogo} alt="Porter" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#PorterNavbarContent"
          aria-controls="PorterNavbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
            <img src={navbarToggler} alt="Toggle navigation" />
          </span>
        </button>

        <div className="collapse navbar-collapse" id="PorterNavbarContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link" href="#buy">
                Purchase Plan
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link login-menu" href="/login">
                Log In
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
