import React from 'react'
import { curveBumpX } from 'd3-shape'
import { theme } from 'twin.macro'
import { ResponsiveContainer, XAxis, YAxis, AreaChart, Area } from 'recharts'

const data = [
  { name: 'Mon', gb: 0.5 },
  { name: 'Tue', gb: 0.3 },
  { name: 'Wed', gb: 0.3 },
  { name: 'Thu', gb: 1.2 },
  { name: 'Fri', gb: 2.5 },
  { name: 'Sat', gb: 2.0 },
  { name: 'Sun', gb: 1.8 }
]

export const ResidentalChart: React.FC = () => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="colorGb" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme`colors.primary`}
              stopOpacity={0.2}
            />
            <stop
              offset="95%"
              stopColor={theme`colors.primary`}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>

        <Area
          type={curveBumpX}
          dataKey="gb"
          stroke={theme`colors.primary`}
          strokeWidth={3}
          fill="url(#colorGb)"
        />
        <XAxis axisLine={false} tickLine={false} dataKey="name" />
        <YAxis axisLine={false} tickLine={false} />
      </AreaChart>
    </ResponsiveContainer>
  )
}
