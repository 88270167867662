import styled from 'styled-components'
import tw from 'twin.macro'

export const Section: any & {
  Header: any
  Content: any
} = styled.div`
  ${tw`bg-accent-300 rounded-xl overflow-hidden`}
`

Section.Header = styled.div`
  ${tw`bg-accent-600 px-4 py-3`}
`

Section.Content = styled.div`
  ${tw`px-4 py-3`}
`
