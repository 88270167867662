import styled from 'styled-components'
import tw from 'twin.macro'

const variants = {
  default: tw`text-gray bg-accent-700 bg-opacity-80 hover:bg-opacity-100 border border-gray border-opacity-20`,
  green: tw`text-green bg-green bg-opacity-20 hover:bg-opacity-30`,
  red: tw`text-red bg-red bg-opacity-20 hover:bg-opacity-30`,
  outline: tw`bg-transparent border border-gray border-opacity-30 hover:(bg-gray bg-opacity-10)`,
  'outline-primary': tw`text-primary bg-transparent border border-gray border-opacity-30 hover:(bg-gray bg-opacity-10)`,
  primary: tw`bg-primary text-white`
}

const sizes = {
  default: tw`px-4 py-2`,
  big: tw`text-xl px-6 py-3`
}

interface Props {
  variant?: keyof typeof variants
  size?: keyof typeof sizes
}

export const Button = styled.button<Props>`
  ${tw`font-semibold px-4 py-2 rounded-md transition duration-150`}
  ${(props) => variants[props.variant || 'default']}
  ${(props) => sizes[props.size || 'default']}
`
