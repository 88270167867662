import { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

export const GlobalStyles = createGlobalStyle`
  body {
    ${tw`text-white`}
  }

  .scroll {
    ::-webkit-scrollbar {
      ${tw`w-2 bg-accent-0`}
    }
    ::-webkit-scrollbar-track {
      ${tw`rounded-full`}
    }

    ::-webkit-scrollbar-thumb {
      ${tw`bg-primary bg-opacity-90 rounded-full`}
    }

    ::-webkit-scrollbar-thumb:hover {
      ${tw`bg-opacity-100`}
    }
  }
`
