import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { Feature } from './components/Feature'
import './styles/bootstrap.css'
import './styles/porter.css'
import './styles/fonts/fonts.css'
import { Hero } from './components/Hero'
import { Footer } from './components/Footer'
import { SupportCta } from './components/SupportCta'
import { FAQ } from './components/FAQ'
import { Navbar } from './components/Navbar'
import { OrderSection } from './components/OrderSection'
import { Residental } from './components/plans/Residental'
import { Complex } from './components/plans/Complex'

export const Homepage: React.FC<RouteComponentProps> = () => {
  return (
    <div className="bootstrap">
      <Navbar />
      <Hero />

      <Feature.Section>
        <Feature
          heading="Rewards System"
          content="Through our exclusive rewards system, customers can earn
                  points by sharing success on social media. Points can then
                  be redeemed for prizes like free residential data,
                  discount codes and more!"
        />
        <Feature
          heading="User Dashboard"
          content="Our dashboard was built with simplicity in mind and can be
                  used to purchase products and manage subscriptions. Upon
                  purchasing, proxies are automatically delivered to dashboard
                  for immediate use."
        />
        <Feature
          heading="Industry Leaders"
          content="Our owners have been raising the bar for years in terms of
                  what large-scale success looks like. We use our products
                  extensively and monitor what is working best in an ever
                  changing environment, and then offer those solutions back to
                  our customers."
        />
        <Feature
          heading="Broad Solutions"
          content="No matter what you are trying to achieve, we have a solution
                  for you. Our offerings range from low-cost IPs for general
                  use, to ISPs where unbanned speed matters, to AIO residential
                  pools. Mix and match to achieve the best results for each use
                  case."
        />
        <Feature
          heading="Authentication"
          content="With user:pass authentication, you will never have to worry
                  about whitelisting your IP addresses or running out of IPs to
                  whitelist! Proxies are ready to go immediately upon delivery."
        />
        <Feature
          heading="Custom Plans"
          content="Need a custom order? We've got you covered. For bulk orders or
                  custom plans, please contact us by opening a ticket via
                  Discord & we'll see what we can do for you!"
        />
      </Feature.Section>

      <OrderSection>
        <OrderSection.Tabs>
          <OrderSection.Tab tabId={1} isActive>
            Residental
          </OrderSection.Tab>
          <OrderSection.Tab tabId={2}>ISP</OrderSection.Tab>
          <OrderSection.Tab tabId={3}>Captcha</OrderSection.Tab>
        </OrderSection.Tabs>
        <OrderSection.Content>
          <OrderSection.TabContent tabId={1} isActive>
            <Residental
              planId="essential"
              title="Essential"
              description="The must-have bandwidth plan, hosted on one of the world's largest
              networks of Residential IPs."
              features={['60 Day Expiry', 'Global Routing', '100M+ IP pool']}
              quantities={[2, 5, 10]}
              prices={[38, 87.5, 150]}
              isSoldOut
            />
            <Residental
              planId="avanced"
              title="Advanced"
              description="The elite bandwidth plan, hosted on private networks and limited in nature to maintain integrity of pools."
              features={[
                '30 Day Expiry',
                'Smaller Private Pool',
                '15M+ IP pool'
              ]}
              quantities={[2, 5, 10]}
              prices={[45, 105, 175]}
            />
          </OrderSection.TabContent>
          <OrderSection.TabContent tabId={2}>
            <Complex
              planId="isp"
              title="ISP"
              description="Benefit from the speed of datacenters with the reliability of residentials."
              quantities={[10, 25, 50, 100]}
              periods={[1, 7, 28]}
              countries={['USA']}
              getPrice={(quantity, period) => quantity * period}
            />
          </OrderSection.TabContent>
          <OrderSection.TabContent tabId={3}>
            <Complex
              planId="captcha"
              title="Captcha"
              description="Hand-picked subnets ideal for Captcha curation."
              quantities={[5, 10, 25, 50]}
              periods={[28, 56, 84]}
              countries={['USA']}
              getPrice={(quantity, period) => quantity * period}
            />
          </OrderSection.TabContent>
        </OrderSection.Content>
      </OrderSection>

      <FAQ.Section>
        <FAQ
          id="One"
          question="What makes Porter Proxies different from competitors?"
          answer="Many competitors offer subpar products meant to capitalize on the rapid growth of
          the sneaker industry. These are offered by people who are only marginally familiar with
          the complexities of a successful setup. Our team is made up of industry leaders who have
          been raising the bar for years in terms of what large-scale success looks like. We don't
          only sell our products - we use them and keep a finger on the pulse of what is working
          best in an ever changing environment, and then offer those solutions out to our customers."
        />
        <FAQ
          id="Two"
          question="How do I purchase proxies?"
          answer="When available, you can purchase plans via our website. Some of our offerings
          sell out quickly, so be sure to follow us on Twitter to be notified of any restocks or
          discount codes! You can visit our website at any time to find out what is currently
          available or sold out."
        />
        <FAQ
          id="Three"
          question="How long will it take to receive my order?"
          answer="Orders are instantly fulfilled by our system upon payment. You can view/manage
          your plan by logging into your dashboard after making a purchase. For 24-hour release
          proxies, your order will be fulfilled 6-12 hours prior to the release."
        />
        <FAQ
          id="Four"
          question="How do I get in touch?"
          answer="The best way to get in touch if you are an existing customer is to open a support
          ticket in our Discord server. For new customers, or if you're not in our Discord,
          please feel free to reach out via Twitter or email us at admin@porterproxies.com."
        />
      </FAQ.Section>

      <SupportCta />
      <Footer />
    </div>
  )
}
