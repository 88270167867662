import React from 'react'
import whiteLogo from '../../../images/porter-color-white-logo.png'

export const Footer: React.FC = () => {
  return (
    <footer className="site-footer">
      <div className="site-footer-line-bg"></div>

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="site-footer-logo-wrapper">
              <a href="/">
                <img src={whiteLogo} alt="Porter Tech" />
              </a>
            </div>
          </div>
          <div className="col-md-6">
            <div className="site-footer-copyright-text">
              Copyright © {new Date().getFullYear()} Porter Tech
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
