import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Select, Input, InputLabel, Button, Section } from '../../../primitives'

export const ResidentalGenerate: React.FC = () => {
  const noop = () => {
    //
  }

  return (
    <Section css={[tw`h-screen flex flex-col`, 'height: calc(100vh - 8rem)']}>
      <Section.Header tw="py-4 px-5">
        <h2 tw="text-lg text-white font-semibold mb-2">
          Generate Residental Proxies
        </h2>

        <div tw="grid grid-cols-2 gap-5 gap-y-3">
          <Select
            label="Type"
            variant="light"
            options={[{ id: 1, name: 'Static' }]}
            selected={{ id: 1, name: 'Static' }}
            onChange={noop}
          />
          <Select
            label="Region"
            variant="light"
            options={[{ id: 1, name: 'All' }]}
            selected={{ id: 1, name: 'All' }}
            onChange={noop}
          />
          <Select
            label="Private Pool"
            variant="light"
            options={[{ id: 1, name: 'Auto' }]}
            selected={{ id: 1, name: 'Auto' }}
            onChange={noop}
          />
          <Select
            label="Website"
            variant="light"
            options={[{ id: 1, name: 'General Use' }]}
            selected={{ id: 1, name: 'General Use' }}
            onChange={noop}
          />
        </div>

        <div tw="grid grid-cols-5 gap-5 mt-3">
          <div>
            <InputLabel>Quantity</InputLabel>
            <Input type="number" value="100" variant="light" />
          </div>

          <div tw="col-span-2 mt-6 border border-gray border-opacity-30 rounded-md flex items-center">
            <Range type="range" value={1} min={0} max={3} tw="w-full mx-5" />
          </div>

          <div tw="col-span-2 mt-6">
            <Button variant="primary" tw="w-full font-medium">
              Generate Proxies
            </Button>
          </div>
        </div>
      </Section.Header>

      <div tw="flex flex-row place-content-between py-3 px-5 border-b border-accent-700 font-semibold">
        <div>Proxy List</div>
        <div tw="flex flex-row">
          <div tw="text-primary px-2 cursor-pointer">Save List</div>
          <div tw="text-primary px-2 cursor-pointer">Copy List</div>
        </div>
      </div>

      <Section.Content
        tw="flex-1 overflow-y-auto font-semibold text-gray"
        className="scroll">
        {[...Array(100)].map((_, i) => (
          <div key={i} tw="mb-1">
            premium.porterproxies.com:12904:abc9ekoefko:fekods
          </div>
        ))}
      </Section.Content>
    </Section>
  )
}

const Range = styled.input`
  -webkit-appearance: none;
  ${tw`block bg-gray bg-opacity-30 w-full h-2 rounded-full`}

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    ${tw`block bg-primary w-4 h-4 rounded-full`}
    cursor: pointer;
  }
`
