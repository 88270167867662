import { Link } from '@reach/router'
import React, { useState } from 'react'
import tw from 'twin.macro'
import porterLogo from '../../../images/porter-color-white-logo.png'
import toggleIcon from '../../../images/navbar-toggler-icon.png'

export const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <nav tw="lg:(bg-accent-300 h-16 w-full border-b border-accent-600 px-10)">
        <div tw="flex flex-col bg-accent-300 lg:(flex-row items-center h-full)">
          <div tw="mx-5 flex flex-row lg:mx-0">
            <Link to="/">
              <img
                src={porterLogo}
                alt="Porter Logo"
                tw="h-8 mt-3 w-auto mr-6"
              />
            </Link>
            <div tw="m-auto mr-0 cursor-pointer lg:hidden">
              <img
                src={toggleIcon}
                alt="Toggle Menu"
                onClick={() => setIsOpen(!isOpen)}
              />
            </div>
          </div>

          <div
            tw="flex-col lg:(flex-row items-center h-full) w-full"
            css={!isOpen ? tw`hidden lg:flex` : tw`flex`}>
            <NavLink to="/dashboard">Dashboard</NavLink>
            <NavLink to="/dashboard/residental">Residental</NavLink>
            <div tw="lg:flex-1" />
            <NavLink to="/dashboardx">Right</NavLink>
          </div>
        </div>
      </nav>
    </>
  )
}

interface NavLinkProps {
  to: string
}

const NavLink: React.FC<NavLinkProps> = ({ children, to }) => {
  return (
    <Link
      to={to}
      getProps={({ isCurrent }) => {
        return {
          style: isCurrent ? tw`text-white` : tw`text-gray-300`
        }
      }}
      tw="hover:text-white! font-medium px-4 table h-12">
      <span tw="table-cell align-middle">{children}</span>
    </Link>
  )
}
