import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { theme } from 'twin.macro'
import { Navbar } from './components/Navbar'
import { Rewards } from './components/Rewards'
import { Stat } from './components/Stat'
import activeOrdersIcon from './images/stat-active-orders.svg'
import lightningIcon from './images/stat-lightning.svg'
import dataIcon from './images/stat-data-yellow.svg'
import { Section } from '../../primitives'
import { OrderDetails } from './components/OrderDetails'

export const Dashboard: React.FC<RouteComponentProps> = () => {
  return (
    <div tw="flex flex-col h-screen">
      <Navbar />

      <div tw="grid grid-cols-12 gap-8 my-5 mx-3 md:m-10 flex-1">
        <div tw="col-span-12 lg:col-span-8 flex flex-col">
          <h1 tw="text-3xl font-bold">
            <FormattedDate
              value={new Date()}
              weekday="long"
              month="short"
              day="numeric"
            />
          </h1>

          <div tw="grid grid-cols-1 lg:grid-cols-3 py-5 border-b border-accent-600">
            <Stat
              value={2}
              label="Active Orders"
              color={theme`colors.primary`}
              icon={activeOrdersIcon}
            />
            <Stat
              value={50}
              label="ISP Proxies"
              color={theme`colors.blue`}
              icon={lightningIcon}
            />
            <Stat
              value="4.0GB"
              label="Resi Data"
              color={theme`colors.yellow`}
              icon={dataIcon}
            />
          </div>

          <h2 tw="text-xl font-semibold mt-10 mb-5">Recent Orders</h2>
          <Section tw="h-full">
            <Section.Header>
              <div tw="grid grid-cols-3 lg:grid-cols-10 py-1 text-gray uppercase text-sm font-semibold items-center">
                <div tw="hidden lg:(block col-span-1)">Order #</div>
                <div tw="col-span-2 lg:col-span-3">Product name</div>
                <div tw="hidden lg:(block col-span-1)">Qty</div>
                <div tw="hidden lg:(block col-span-1)">Total</div>
                <div tw="hidden lg:(block col-span-1)">Ordered</div>
                <div tw="hidden lg:(block col-span-1)">Active Time</div>
                <div tw="col-span-1 lg:col-span-2 text-right">Status</div>
              </div>
            </Section.Header>
            <OrderDetails id="7966-0" />
            <OrderDetails id="7213-0" />
          </Section>
        </div>

        <div tw="col-span-12 lg:col-span-4">
          <Rewards />
        </div>
      </div>
    </div>
  )
}
