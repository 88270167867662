import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useForm } from 'react-hook-form'
import 'twin.macro'
import { Button, Input, InputError } from '../../primitives'
import { AuthAction } from './components/AuthAction'

const links = [{ label: 'Log in', href: '/login' }]

export const Register: React.FC<RouteComponentProps> = () => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm()
  const onSubmit = (payload: unknown) => alert(JSON.stringify(payload))

  return (
    <AuthAction title="Register" links={links}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div tw="flex flex-col gap-3">
          <Input
            type="text"
            placeholder="First Name"
            variant="light"
            tw="w-full"
            {...register('firstName', { required: true })}
          />
          <InputError error={errors.firstName} />
          <Input
            type="text"
            placeholder="Last Name"
            variant="light"
            tw="w-full"
            {...register('lastName', { required: true })}
          />
          <InputError error={errors.lastName} />
          <Input
            type="email"
            placeholder="E-mail"
            variant="light"
            tw="w-full"
            {...register('email', { required: true })}
          />
          <InputError error={errors.email} />
          <Input
            type="password"
            placeholder="Password"
            variant="light"
            tw="w-full"
            {...register('password', { required: true })}
          />
          <InputError error={errors.password} />
          <Input
            type="password"
            placeholder="Repeat Password"
            variant="light"
            tw="w-full"
            {...register('passwordAgain', { required: true })}
          />
          <InputError error={errors.passwordAgain} />
          <Button type="submit" variant="primary">
            Register
          </Button>
        </div>
      </form>
    </AuthAction>
  )
}
