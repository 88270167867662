import { Link } from '@reach/router'
import React from 'react'
import 'twin.macro'
import porterLogo from '../../../images/porter-color-white-logo.png'

interface LinkProps {
  label: string
  href: string
}

interface Props {
  title: string
  links?: LinkProps[]
}

export const AuthAction: React.FC<Props> = ({ title, children, links }) => {
  return (
    <div tw="h-screen flex items-center place-content-center">
      <div tw="w-80 bg-accent-300 rounded-lg pt-5 overflow-hidden">
        <img src={porterLogo} alt="Porter Logo" tw="mx-auto my-3" />
        <h1 tw="text-2xl font-semibold text-center mb-5">{title}</h1>

        <div tw="px-4">{children}</div>

        {links && (
          <div tw="flex flex-row mt-2 divide-x divide-accent-600">
            {links.map((link) => (
              <div key={link.label} tw="py-3 h-14 flex flex-grow items-center">
                <Link
                  to={link.href}
                  tw="mx-auto text-sm text-center hover:text-gray">
                  {link.label}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
