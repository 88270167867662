import React from 'react'
import tw from 'twin.macro'
import { ProgressRing } from '../../../primitives'

interface Props {
  icon?: string
  progress?: number

  value: string | number
  label: string
  color: string
}

export const Stat: React.FC<Props> = ({
  value,
  icon,
  progress,
  label,
  color
}) => {
  const indicator = icon ? (
    <div tw="w-16 h-16 bg-accent-300 rounded-full">
      <img src={icon} alt="" tw="w-8 m-auto h-full" />
    </div>
  ) : (
    <ProgressRing progress={progress!} color={color} />
  )

  return (
    <div tw="flex flex-row gap-4 my-3 items-center">
      {indicator}
      <div css={[tw`text-4xl 2xl:text-6xl font-bold`, `color: ${color}`]}>
        {value}
      </div>
      <div tw="text-lg 2xl:text-2xl font-semibold text-gray w-min">{label}</div>
    </div>
  )
}
