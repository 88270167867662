import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { theme } from 'twin.macro'
import { Navbar } from './components/Navbar'
import { Stat } from './components/Stat'
import dataIcon from './images/stat-data.svg'
import { Button, Progress, Section, Select } from '../../primitives'
import { FormattedDate } from 'react-intl'
import { ResidentalChart } from './components/ResidentalChart'
import { ResidentalGenerate } from './components/ResidentalGenerate'
import porterIcon from './images/porter-icon.png'

export const Residental: React.FC<RouteComponentProps> = () => {
  return (
    <div tw="flex flex-col">
      <Navbar />

      <div tw="grid grid-cols-12 gap-8 my-5 mx-3 md:m-10 flex-1">
        <div tw="col-span-12 lg:col-span-8">
          <div tw="grid grid-cols-1 lg:grid-cols-3 py-5 border-b border-accent-600">
            <Stat
              icon={dataIcon}
              value="2.0"
              label="Plan GB"
              color={theme`colors.primary`}
            />
            <Stat
              progress={0.3}
              value={0.45}
              label="GB Usage"
              color={theme`colors.primary`}
            />
            <Stat
              progress={0.666}
              value={16}
              label="Days Left"
              color={theme`colors.gray`}
            />
          </div>

          <div tw="flex flex-row place-content-between items-center mt-5 mb-10">
            <h2 tw="text-xl font-semibold">Residental Data Usage</h2>
            <div tw="flex gap-2">
              <Select
                selected={{ id: 8492, name: 'Plan #8492' }}
                options={[{ id: 8492, name: 'Plan #8492' }]}
                onChange={(a) => {
                  //
                }}
              />
              <Select
                selected={{ id: 1, name: 'Weekly' }}
                options={[
                  { id: 2, name: 'Monthly' },
                  { id: 1, name: 'Weekly' }
                ]}
                onChange={(a) => {
                  //
                }}
              />
            </div>
          </div>
          <ResidentalChart />

          <Section tw="mt-5">
            <Section.Header tw="flex flex-row place-content-between items-center">
              <div>Plan #8409</div>
              <div>
                <Button as="div" variant="green" tw="hover:bg-opacity-20">
                  Active
                </Button>
              </div>
            </Section.Header>

            <Section.Content tw="font-semibold">
              <div tw="grid md:grid-cols-3 my-3">
                <div tw="flex flex-row gap-2">
                  <img src={porterIcon} alt="" tw="h-6 w-6 hidden lg:inline" />
                  <div tw="text-gray">Order ID:</div>
                  <div>8409</div>
                </div>
                <div>
                  <span tw="text-gray">Bandwidth: </span>
                  <span>2.0 GB</span>
                </div>
                <div>
                  <span tw="text-gray">Purchase Date: </span>
                  <FormattedDate value={new Date()} />
                </div>
              </div>

              <div tw="grid md:grid-cols-2 gap-5 text-gray mt-3">
                <div>
                  1.3 of 2.0 GB Remaining
                  <Progress
                    value={0.3}
                    tw="w-full"
                    color={theme`colors.primary`}
                  />
                </div>
                <div>
                  23 days remaining
                  <Progress value={0.3} tw="w-full" />
                </div>
              </div>
            </Section.Content>
          </Section>

          <Section tw="mt-5">
            <Section.Header tw="mb-2">
              <div>Request Statistics</div>
              <div tw="grid grid-cols-4 uppercase text-sm text-gray font-semibold">
                <div tw="col-span-2">Date</div>
                <div>Requests</div>
                <div>Traffic</div>
              </div>
            </Section.Header>

            {[
              [Date.now(), '4893', '0.003'],
              [Date.now(), '4893', '0.003'],
              [Date.now(), '4893', '0.003']
            ].map(([date, requests, traffic], key) => (
              <div tw="grid grid-cols-4 py-1 px-5" key={key}>
                <div tw="col-span-2">
                  <FormattedDate value={date} />
                </div>
                <div>{requests}</div>
                <div>{traffic}</div>
              </div>
            ))}
          </Section>
        </div>

        <div tw="col-span-12 lg:col-span-4">
          <ResidentalGenerate />
        </div>
      </div>
    </div>
  )
}
