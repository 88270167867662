import React from 'react'
import heroImg from '../images/main-hero-image.png'

export const Hero: React.FC = () => {
  return (
    <section className="main-hero-section">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="main-hero-content-wrapper">
              <div className="main-hero-content-wrap">
                <h1 className="main-hero-content-title">
                  Setting The New Standard.
                </h1>
                <h2 className="main-hero-content-subtitle">
                  A comprehensive private solution of unrivaled performance.
                </h2>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="main-hero-image-wrapper">
              <div className="main-hero-image-wrap">
                <img src={heroImg} alt="" className="heroImageAnimation" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-hero-section-line-bg"></div>
    </section>
  )
}
