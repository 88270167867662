import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import wrapperBg from '../images/rewards-bg.png'
import coinsImg from '../images/rewards-coins.png'

export const Rewards: React.FC = () => {
  return (
    <Wrapper>
      <img src={coinsImg} alt="Coin Icon" tw="mb-5" />
      <div tw="text-2xl">Coin Redemption</div>
      <div tw="text-3xl font-semibold text-gray">Coming Soon</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${tw`bg-accent-300 rounded-xl flex flex-col justify-center items-center h-full py-10`}
  min-height: 30vh;
  background-image: url(${wrapperBg});
  background-size: cover;
`
