import React from 'react'
import ReactDOM from 'react-dom'
import App from './features/app/App'
import './lib/analytics'
import './lib/sentry'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
